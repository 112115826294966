



























































import {Component, Vue} from 'vue-property-decorator'
import {VBtn, VCard, VCardSubtitle, VCardText, VCardTitle, VForm, VProgressCircular, VTextField} from 'vuetify/lib'
import {AdminPermission} from '~/utils/permissions'
import {routeDict} from '~/router'
import {createRequest} from '~/utils/network-request'

@Component({
    components: {
        VCard,
        VProgressCircular,
        VCardTitle,
        VCardSubtitle,
        VCardText,
        VForm,
        VTextField,
        VBtn
    },
    metaInfo() {
        return {
            title: 'Login'
        }
    }
})
export default class LoginPage extends Vue {

    // sign in buttons
    isLoading: boolean = false
    isSignInDisabled: boolean = false

    // form
    isFormValid: boolean = true
    email: string = ''
    password: string = ''

    // form error
    error: boolean = false
    errorMessages: string = ''

    errorProvider: boolean = false
    errorProviderMessages: string = ''

    // show password field
    showPassword: boolean = false

    // input rules
    rules = {
        required: (value) => (value && Boolean(value)) || 'Required'
    }

    //  auto login
    isAutoLoginLoading: boolean = false

    get form() {
        return this.$refs.form as any
    }

    created() {
        if (this.$route.query.token) {
            this.isAutoLoginLoading = true
            this.$store.dispatch('user/userLoginWithToken', this.$route.query.token).then(success => {
                success ? this.postLogin() : this.isAutoLoginLoading = false
            })
        }
    }

    submit() {
        if (this.form.validate()) {
            this.isLoading = true
            this.isSignInDisabled = true
            this.signIn(this.email, this.password)
        }
    }

    async signIn(email, password) {
        try {
            const res = await createRequest('login', 'post', {}, {
                email: email,
                password: password
            }).send(false)

            this.$auth.login(res.data.member_id, res.data)

            await this.postLogin()
        } catch (e) {
            this.isLoading = false
            this.isSignInDisabled = false
            console.log(e)
            alert('Oops. ' + e.message)
        }
    }

    async postLogin() {
        await this.$store.dispatch('user/fetchUserData', this.$auth.memberId)
        //  Check user have admin page access
        if (!this.checkPermissions(AdminPermission.AdminPage)) {
            this.isAutoLoginLoading = false
            alert('Only admin can access to admin page')
            await this.$store.dispatch('user/userLogout')
            return
        }
        this.goNext()
    }

    goNext() {
        const redirect = window.sessionStorage.getItem('after-login')
        if (redirect) {
            window.sessionStorage.removeItem('after-login')
            this.$router.push(redirect)
        } else {
            this.$router.push(routeDict['course-list'].path)
        }
    }

    resetErrors() {
        this.error = false
        this.errorMessages = ''

        this.errorProvider = false
        this.errorProviderMessages = ''
    }
}
